.How_it_works_wrapper {
  text-align: center;
  margin-bottom: 144px;
  padding-top: 80px;
  position: relative;
  z-index: 2;
}

.How_it_works_list {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.How_it_works_element {
  width: 800px;
  max-width: 90%;
  margin: 14px 36px;
  position: relative;
}

.How_it_works_element_inner {
  padding: 34px;
  min-height: 330px;
  background: white;
  border-radius: 34px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  display: flex;
}

.title {
  font-size: 34px;
  margin-bottom: 12px;
}

.How_it_works_element_title {
  font-weight: bolder;
  font-size: 26px;
  position: relative;
  z-index: 1;
  margin-bottom: 12px;
  min-height: 30px;
  margin-top: 4px;
}

.How_it_works_element_desc {
  z-index: 1;
  position: relative;
}

.How_it_works_number {
  font-size: 60px;
  color: #a59bbbb8;
  line-height: 63px;
  font-weight: 900;
}

.How_it_works_element_pic {
  // background: #aedfff;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 9px;
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .How_it_works_element_pic_element {
    max-width: 100%;
    height: auto;
  }
}

.How_it_works_element_inner_right {
  text-align: left;
  flex-basis: 50%;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subtitle {
  margin-bottom: 44px;
  text-align: center;
}

@media screen and (max-width: 800px) {

  .How_it_works_element_inner {
    flex-wrap: wrap;

    .How_it_works_element_pic,
    .How_it_works_element_inner_right {
      flex-basis: 100%;
    }
  }

  .How_it_works_element {
    width: 500px;
  }


}