.Ready_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  max-width: calc(100% - 36px);
  margin: auto;
}

.ReadyBilly_logos {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-top: 12px;
  flex-wrap: wrap;
}

.Ready_inner {
  width: 870px;
  max-width: calc(100% - 24px);
  background: linear-gradient(120deg, #bbc5f7, #b3a1f5) white;
  padding: 80px 64px;
  border-radius: 46px;
  display: flex;
  margin: 12px;
}

.Ready_title {
  max-width: 560px;
  font-size: 36px;
}

.Ready_content {
  flex-grow: 1;
  min-width: 285px;
  margin: 14px;
}

.Ready_logo {
  width: 214px;
  margin: 14px;
  height: auto;
}

.Action_wrapper {
  margin-top: 48px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 670px) {
  .Ready_inner {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    background: linear-gradient(0deg, #bbc5f7, #b3a1f5) white;
  }

  .Ready_logo {
    width: 184px;
  }

  .Ready_content {
    min-width: unset;
  }
}


@media screen and (max-width: 460px) {
  .Ready_inner {
    padding: 44px 38px;
  }

  .Action_wrapper {
    flex-wrap: wrap;
  }

}

@media screen and (max-width: 400px) {
  .Ready_inner {
    padding: 40px 32px;
  }

  .Ready_logo {
    width: 184px;
  }

  .Ready_content {
    min-width: unset;
  }
}