.Trusted_by_wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 144px;
  padding-top: 80px;
  position: relative;
  z-index: 2;
}

.withoutBackground {
  .Trusted_by_inner {
    background: transparent;
    color: #4b4b4b;
  }

  .Trusted_by_quote {
    color: #4b4b4b;
  }
}

.cta_readmore {
  color: white;
}

.Trusted_by_inner {
  padding: 40px 44px;
  width: 1000px;
  max-width: calc(100% - 88px);
  margin: 12px;
  background: linear-gradient(45deg, #450202, #0e203f);
  border-radius: 34px;
  color: white;
  position: relative;

  .ant-carousel {
    width: 100%;
  }
}

.Trusted_by_title {
  background: linear-gradient(270deg,
      #00a7ff 10.33%,
      #be14e6 34.36%,
      #ffd500 60.15%,
      #ff0000 84.06%,
      #9b0f0f 92.67%,
      #ff0000 103.14%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 20px;
  font-size: 46px;
}

.Trusted_by_quote {
  display: block !important;
  width: fit-content;
  padding: 12px;
  flex-basis: calc(50% - 24px);
  margin-bottom: 50px;
  color: white;

  .Trusted_by_quote_inner {
    text-align: justify;
  }
}

.Trusted_by_image {
  display: flex;
  justify-content: flex-end;

  .Trusted_by_image_element {
    border-radius: 50%;
    overflow: hidden;
  }
}

.Trusted_by_quote_wrapper {
  display: flex;
  flex-wrap: wrap;

  &.mob {
    display: block;
  }
}

.quote {
  font-size: 20px;
  font-weight: 100;
}

.Trusted_mobile_slider {
  width: 100%;
  // display: block;

  .Trusted_by_image {
    justify-content: center;
    margin-top: 12px;
  }

  .Trusted_by_quote {
    width: auto;
    flex-basis: calc(100% - 24px);
    margin-bottom: 0px;

    .Trusted_by_quote_inner {
      text-align: justify;
    }
  }
}

.Trusted_mobile_infos_wrapper {
  display: flex;
  width: 100%;
}

.Trusted_mobile_info_number {
  margin-right: 24px;
  display: flex;
  align-items: center;
  flex-grow: 1;
}



.Trusted_mobile_info_prev {
  color: white;

  &:hover,
  &:focus {
    color: white;
    background-color: #ffffff26;
  }

  &:focus {
    background-color: transparent;
  }
}

.Trusted_mobile_info_next {
  color: white;

  &:hover,
  &:focus {
    color: white;
    background-color: #ffffff26;
  }

  &:focus {
    background-color: transparent;
  }
}


.Trusted_by_customer {
  text-align: right;
  margin-top: 12px;
}

@media screen and (max-width: 950px) {
  .Trusted_by_customer {
    text-align: center;
  }
}

@media screen and (max-width: 850px) {
  .Trusted_by_quote {
    display: contents !important;
  }
}

@media screen and (max-width: 670px) {
  .Trusted_by_quote {
    flex-basis: 100%;
  }
}