.FeaturesTag_wrapper {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}
.FeaturesTag_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 14px;
  border-radius: 18px;
  padding: 4px 32px;
  background: #e4def8;
  margin-left: 12px;
  margin-right: 12px;
}
.FeaturesTag_element {
  margin: 6px 12px;
  display: flex;
  align-items: center;
}
.FeaturesTag_icon {
  margin-right: 8px;
}
.FeaturesTag_name {
  font-weight: bold;
}
.FeaturesTag_title {
  margin-right: 6px;
  text-transform: uppercase;
  color: #6f7dab;
  font-weight: 700;
}
.FeaturesTag_link {
  a {
    text-decoration: underline;
  }
}
@media screen and (max-width: 800px) {
  .FeaturesTag_inner {
    flex-direction: column;
    padding: 24px;
  }
  .FeaturesTag_element {
    margin: 3px 6px;
  }
  .FeaturesTag_title {
    margin-bottom: 10px;
    margin-right: 0;
  }
  .FeaturesTag_link {
    margin-top: 12px;
  }
}

// @media screen and (max-width: 460px) {
//     .FeaturesTag_inner {
//         padding: 4px 24px;
//     }
//   }
