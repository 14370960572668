.rated {
    margin-top: 24px;
    display: flex;
    align-items: center;

    ul {
        font-size: 19px;

        li {
            margin-inline-end: 3px !important;
        }
    }
}

.rate {
    margin-left: 8px;
    text-align: left;

    ul {
        padding-inline-start: 0;
    }
}

.image {
    // margin-top: 5px;
    // margin-left: 12px;
}

.ratedText {
    margin-top: 4px;
    color: #878fb1;
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
}

.badgeImage {
    margin-top: 8px;
}

.bordered {
    margin: 0;
    padding: 8px 20px;
    border: 1px solid #e4e4e4;
    border-radius: 40px;
}